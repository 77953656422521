import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Container,
  Title,
  Content,
  Button,
  Grid,
  Card,
  Image
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ pageContext, data }) => {
  //console.log(data)

  const { tag } = pageContext

  return (
  <Layout>
    <SEO
      title={`${tag} - Projectival Blog`}
      description=""
    />
    <Container>
      <Container.Inner>
        <Title as="h1">{`${tag}`} {/*data.allMarkdownRemark.edges.length*/}</Title>
        <Grid className="nb5 flex-wrap-l">
          {data.allMarkdownRemark.edges.map(({ node }) =>
            <Grid.Col className="mb5 w-50-l" key={node.id}>
              <Card as={Link} to={node.fields.slug}>
                {node.frontmatter.image &&
                  <Card.ImageWrapper>
                    <Image as={Img} fluid={node.frontmatter.image.childImageSharp.fluid} />
                  </Card.ImageWrapper>
                }
                <Card.Inner>
                  <Title smaller>{node.frontmatter.title}</Title>
                  {/*<Content dangerouslySetInnerHTML={{__html: node.excerpt}} />*/}
                </Card.Inner>
              </Card>
            </Grid.Col>
          )}
        </Grid>
      </Container.Inner>
    </Container>
     
  </Layout>
)}

export const query = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        fileAbsolutePath: {regex: "\/blog/"},
        frontmatter: {
          draft: {ne: true}
          tags: { in: [$tag] }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            image {
              ...postImage
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
